$txc-orange: #e9af11;
$txc-red: #900028;

.md-form {
  .fa-globe-americas,
  .fa-user,
  .fa-lock,
  .fa-copy,
  .fa-fingerprint,
  .fa-envelope,
  .fa-tag,
  .fa-pencil-alt {
    transition: 0.2s;
    &.active {
      color: $txc-orange;
    }
  }
  input {
    &:focus {
      box-shadow: 0 1px 0 0 $txc-orange !important;
      border-bottom: 1px solid $txc-orange !important;
    }
  }
  label {
    &.active {
      color: $txc-orange !important;
    }
  }
}
// BUTTON STYLES
.custom-btn {
  background-color: $txc-orange !important;
  &.btn-default:not([disabled]):not(.disabled):active {
    background-color: $txc-orange !important;
  }
}
.destroy-btn {
  background: $txc-red !important;
  &.btn-default:not([disabled]):not(.disabled):active {
    background-color: $txc-red !important;
  }
}

.client-send {
  margin-top: 40px;
}

.txc-red {
  color: $txc-red;
  &:hover {
    text-decoration: underline;
  }
}

.disclaimer {
  font-size: 13px;
}

.alert {
  margin-top: 20px;
}
// PAGE UNIVERSALS
// .user-page,
// .admin-page,
// .txc-user {
//   flex: 1;
//   form {
//     margin-top: 20px;
//     .md-form {
//       max-width: 360px;
//       margin: 0 auto;
//       margin-bottom: 20px;
//     }
//     &.client-send {
//       .md-form {
//         max-width: 100%;
//       }
//     }
//   }

//   .admin-login {
//     position: absolute;
//     bottom: 10px;
//     right: 10px;
//     color: $txc-orange;
//   }
// }

.copy-text {
  margin: 0 auto;
  max-width: 500px;
  margin-top: 36px;
  .md-form {
    margin: 0;
    flex: 1;
    input {
      margin-bottom: 0;
      &::selection {
        background: $txc-orange;
      }
    }
  }
  button {
    margin: 0;
    padding: 0 20px;
  }
}

.admin-list {
  .md-form {
    position: absolute;
    opacity: 0;
  }
}
